.MuiDataGrid-cell {
  border-right: 1px solid #ddd;
}

.MuiDataGrid-row .MuiDataGrid-cell:last-child {
  border-right: none;
}

.MuiDataGrid-columnHeader {
  border-right: 1px solid #ddd;
}

.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:last-child {
  border-right: none;
}
